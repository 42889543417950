import React from "react";
import logo from "../logo.svg";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "../components/LoginButton";

const LoginPage = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  return (
    <div className="loginPage">
      <header className="loginHeader">
        <img src={logo} className="App-logo" alt="logo" />
        <p>WELCOME TO GOULASH</p>
        <LoginButton></LoginButton>
      </header>
    </div>
  );
};

export default LoginPage;
