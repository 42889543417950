import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const CustomerQueryForm = (props) => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  const [customerID, setCustomerID] = useState(props.customerID);
  const [maxResults, setMaxResults] = useState(props.maxResults);
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);

  return (
    <div>
      <div className="customer-form-inputs">
        <div className="customer-inputs-left">
          <div>
            <label>CustomerID: </label>
            <input type="text" id="customerID" name="customerID" value={customerID} onChange={(e) => setCustomerID(e.target.value)} />
          </div>
          <div>
            <label>Max Results: </label>
            <input type="text" id="maxResults" name="maxResults" value={maxResults} onChange={(e) => setMaxResults(e.target.value)} />
          </div>
        </div>
        <div className="customer-inputs-right">
          <div>
            <label>Start Date: </label>
            <input type="text" id="startDate" name="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
          </div>
          <div>
            <label>End Date: </label>
            <input type="text" id="endDate" name="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
          </div>
        </div>
      </div>
      <button onClick={(e) => props.submit(customerID, maxResults, startDate, endDate)}>Submit</button>
    </div>
  );
};

export default CustomerQueryForm;
