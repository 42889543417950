import React, { useState } from "react";
import ReactJson from "react-json-view";
import Table from "react-bootstrap/Table";
import { gql, useQuery } from "@apollo/react-hooks";
import { OGGetActivityLog } from "../../graphql/queries";

const CustomerActivityLog = (props) => {

let variables = { after: props.startDate, before: props.endDate, customerId: props.customerID, first: parseInt(props.maxResults) }
console.log(variables);

  const { loading, error, data } = useQuery(gql(OGGetActivityLog), {
    variables
  });

  if (loading) {
    return <div className="loading">LOADING</div>;
  } else if (error) {
    return (
      <div>
        error:{" "}
        {error.toString()}
      </div>
    );
  }

  const entries = data.OGGetActivityLog;

  return (
    <div>
      {entries.length !== 0 && (
        <div className="activityListTable">
          <Table responsive="sm" className="activity-table">
            <thead>
              <tr>
                
                <th>Email</th>
                <th>Date</th>
                <th>Entry</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {entries.map((entry) => {
                return (
                  <tr>
                    <td>
                      {entry.Email}
                    </td>
                    <td>
                      {entry.OccurredOn}
                    </td>
                    <td>
                      {
                        <ReactJson
                          src={entry}
                          name={null}
                          collapsed={true}
                          collapseStringsAfterLength={50}
                          displayDataTypes={false}
                          displayObjectSize={false}
                          theme="summerfruit:inverted"
                        />
                      }
                    </td>
                    <td className="copy-col">
                      <button
                        onClick={() =>
                          props.copy({
                            entry,
                          })
                        }
                      >
                        Copy
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
      {entries.length === 0 && <p>No Activity Found</p>}
    </div>
  );
};

export default CustomerActivityLog;
