import React from "react";
import ReactJson from "react-json-view";
import { ENTITY_VIEW, TRACE_ROOT_VIEW } from "../../pages/PotatoObservationExplorer";
import Table from "react-bootstrap/Table";

import { gql, useQuery } from "@apollo/react-hooks";
import { getPotatoObservationByEntity, getPotatoObservationByTraceroot } from "../../graphql/queries";

const ObservationList = (props) => {
  console.log("props to ObservationList", { props });
  let query;
  let variables;
  if (props.form.view === ENTITY_VIEW) {
    query = getPotatoObservationByEntity;
    variables = {
      entity: props.form.entity,
      entityid: parseInt(props.form.entityid),
      type: props.form.type,
      first: 100,
    };
  } else {
    query = getPotatoObservationByTraceroot;
    variables = {
      traceroot: props.form.traceroot,
      first: 100,
    };
  }

  const { loading, error, data } = useQuery(gql(query), { variables });

  if (props.form.view === ENTITY_VIEW) {
    if (!variables.entity || !variables.entityid || !variables.type) {
      return <div>Missing param</div>;
    }
  } else {
    if (!variables.traceroot) {
      return <div>Missing param</div>;
    }
  }
  if (loading) {
    return <div className="loading">LOADING</div>;
  } else if (error) {
    return (
      <div>
        error:
        {error.toString()}
      </div>
    );
  }
  console.log(data);

  const result = props.form.view === ENTITY_VIEW ? data.getPotatoObservationsByEntity : data.getPotatoObservationsByTraceroot;

  console.log(result);
  const observations = result.edges.map((e, key) => {
    return {
      ...e.node,
      key,
    };
  });
  const hasNextPage = result.pageInfo.hasNextPage;

  console.log("observations", { observations });

  const tableClassName = props.form.view === ENTITY_VIEW ? "entity-table" : "traceroot-table";

  return (
    <div className="observationListTable">
      {hasNextPage && <div>there's more!</div>}
      <Table responsive="sm" className={tableClassName}>
        <thead>
          <tr>
            <th className="observation-list-cols"></th>
            {props.form.view === ENTITY_VIEW && <th className="observation-list-cols">traceroot</th>}
            {props.form.view === TRACE_ROOT_VIEW && <th className="observation-list-cols">entity</th>}
            {props.form.view === TRACE_ROOT_VIEW && <th className="observation-list-cols">entityid</th>}
            {props.form.view === TRACE_ROOT_VIEW && <th className="observation-list-cols">type</th>}
            <th className="observation-list-cols">timestamp</th>
            <th className="observation-list-cols" colSpan={4}>
              data
            </th>
            <th className="observation-list-cols">diff</th>
          </tr>
        </thead>
        <tbody>
          {observations.map((observation) => {
            console.log(observation);
            return (
              <tr key={observation.key}>
                <td className="copy-col">
                  <button
                    onClick={() =>
                      props.copy({
                        ...(props.form.view === ENTITY_VIEW && {
                          traceroot: observation.traceroot,
                          entityid: observation.entityid,
                          timestamp: observation.timestamp,
                          data: observation.data,
                          diff: observation.diff,
                        }),
                        ...(props.form.view === TRACE_ROOT_VIEW && {
                          entity: observation.entity,
                          entityid: observation.entityid,
                          timestamp: observation.timestamp,
                          data: observation.data,
                          diff: observation.diff,
                        }),
                      })
                    }
                  >
                    Copy
                  </button>
                </td>
                {props.form.view === ENTITY_VIEW && (
                  <td className="traceroot-col">
                    <button
                      className="fake-link"
                      onClick={() =>
                        props.submit({
                          view: TRACE_ROOT_VIEW,
                          traceroot: observation.traceid.split("-")[0],
                        })
                      }
                    >
                      {observation.traceid.split("-")[0]}
                    </button>
                  </td>
                )}
                {props.form.view === TRACE_ROOT_VIEW && <td className="entity-col">{observation.entity}</td>}
                {props.form.view === TRACE_ROOT_VIEW && (
                  <td className="entityid-col">
                    <button
                      className="fake-link"
                      onClick={() =>
                        props.submit({
                          view: ENTITY_VIEW,
                          entity: observation.entity,
                          entityid: observation.entityid,
                          type: observation.type,
                        })
                      }
                    >
                      {observation.entityid}
                    </button>
                  </td>
                )}
                {props.form.view === TRACE_ROOT_VIEW && <td className="type-col">{observation.type}</td>}
                <td className="time-col">
                  {
                    //time
                    observation.time
                  }
                </td>
                <td className="data-col">
                  {
                    <ReactJson
                      src={JSON.parse(observation.data)}
                      name={null}
                      collapsed={true}
                      collapseStringsAfterLength={50}
                      displayDataTypes={false}
                      displayObjectSize={false}
                      theme="summerfruit:inverted"
                    />
                  }
                </td>
                <td className="diff-col">{observation.diff}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ObservationList;
