/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($customerID: ID!) {
    getCustomer(customerID: $customerID) {
      customerID
      salesforceID
      email
      programCode
      firstName
      lastName
      homePhone
      phone
      billingAddress {
        country
        postalCode
        street
        state
        city
      }
    }
  }
`;

export const getPotatoObservationByEntity = `
  query GetPotatoObservationsByEntity($entity: String, $entityid: String, $type: String, $after: String, $first: Int) {
    getPotatoObservationsByEntity(entity: $entity, entityid: $entityid, type: $type, after: $after, first: $first) {
      pageInfo {
        endCursor
        hasNextPage
        startCursor
      }
      edges {
        node {
          data
          dataref
          entity
          observer
          observerid
          schema
          time
          entityid
          traceid
          type
          version
        }
      }
    }
  }
`;

export const getPotatoObservationByTraceroot = `
  query GetPotatoObservationsByTraceroot($traceroot: String, $after: String, $first: Int) {
    getPotatoObservationsByTraceroot(traceroot: $traceroot, after: $after, first: $first) {
      pageInfo {
        endCursor
        hasNextPage
        startCursor
      }
      edges {
        node {
          data
          dataref
          entity
          entityid
          observer
          observerid
          schema
          time
          traceid
          type
          version
        }
      }
    }
  }
`;

export const OGGetActivityLog = `
  query OGGetActivityLog($after: String, $before: String, $customerId: String, $first: Int) {
    OGGetActivityLog(after: $after, before: $before, customerId: $customerId, first: $first) {
      CustomerId
      DeviceGuid
      DeviceName
      Email
      EmailAffected
      EmailPreformed
      ExtraData
      IpAddress
      OccurredOn
      PosOnlineActivityLogEntryGuid
      PosOnlineActivitySubtype
      PosOnlineActivityType
      UserGuidAffected
      UserGuidPreformed
    }
  }
`;
