import React, { useState } from "react";
import Inputs from "../components/PotatoDebugUI/Inputs";
import ObservationList from "../components/PotatoDebugUI/ObservationList";

const PotatoObservationExplorer = (props) => {
  const [form, setForm] = useState({ view: ENTITY_VIEW, entity: "daysmart.potato.api.customer", entityid: "508884", type: "full" });

  return (
    <div className="App">
      <header className="App-header">
        <Inputs
          form={form}
          submit={(form) => {
            console.log(form);
            setForm(form);
          }}
        ></Inputs>
        {form.view === ENTITY_VIEW && (
          <div className="showing">
            showing entity {form.entity}
            {form.entityid && " with entityid " + form.entityid}
          </div>
        )}
        {form.view === TRACE_ROOT_VIEW && <div>showing traceroot {form.traceroot}</div>}
        <ObservationList
          form={form}
          submit={(form) => {
            console.log(form);
            setForm(form);
          }}
          copy={(observation) => {
            var el = document.createElement("textarea");
            el.value = JSON.stringify(observation);
            el.setAttribute("readonly", "");
            el.style = { position: "absolute", left: "-9999px" };
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            console.log(el.value);
            document.body.removeChild(el);
          }}
        ></ObservationList>
      </header>
    </div>
  );
};

export const ENTITY_VIEW = "ENTITY_VIEW";
export const TRACE_ROOT_VIEW = "TRACE_ROOT_VIEW";

export default PotatoObservationExplorer;
