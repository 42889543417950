import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "../components/LogoutButton";
import Profile from "../components/Profile";
import CustomerQueryForm from "../components/CustomerQueryUI/CustomerQueryForm";
import Customer from "../components/CustomerQueryUI/Customer";
import CustomerActivityLog from "../components/CustomerQueryUI/CustomerActivityLog";

const CustomerFormPage = (props) => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  const [customerID, setCustomerID] = useState("123456");
  const [maxResults, setMaxResults] = useState("10");
  const [startDate, setStartDate] = useState("2021-02-01T20:55:15");
  const [endDate, setEndDate] = useState("2021-02-11T20:55:15");

  return (
    <div className="App">
      <header className="App-header">
        <br />
        <label for="CID">Welcome Back {user.name}, Please Input Your Customer Information:</label>
        <Profile></Profile>
        <div>
          <CustomerQueryForm
            customerID={customerID}
            maxResults={maxResults}
            startDate={startDate}
            endDate={endDate}
            submit={(customerID, maxResults, startDate, endDate) => {
              setCustomerID(customerID);
              setMaxResults(maxResults);
              setStartDate(startDate);
              setEndDate(endDate);
            }}
          ></CustomerQueryForm>
          <br />
          <br />
          <h3>Customer Info</h3>
          <Customer customerID={customerID}></Customer>
          <br />
          <h3>Customer Activity Log</h3>
          <CustomerActivityLog
            customerID={customerID}
            maxResults={maxResults}
            startDate={startDate}
            endDate={endDate}
            copy={(entry) => {
              console.log(entry);
              var el = document.createElement("textarea");
              el.value = JSON.stringify(entry);
              el.setAttribute("readonly", "");
              el.style = { position: "absolute", left: "-9999px" };
              document.body.appendChild(el);
              el.select();
              document.execCommand("copy");
              document.body.removeChild(el);
            }}
          ></CustomerActivityLog>
        </div>
        <LogoutButton></LogoutButton>
      </header>
    </div>
  );
};

export default CustomerFormPage;
