import React, { useState } from "react";
import { ENTITY_VIEW, TRACE_ROOT_VIEW } from "../../pages/PotatoObservationExplorer";

const Inputs = (props) => {
  const [view, setView] = useState(ENTITY_VIEW);
  const [entity, setEntity] = useState(props.form.entity);
  const [entityid, setEntityid] = useState(props.form.entityid);
  const [type, setType] = useState(props.form.type);
  const [traceroot, setTraceroot] = useState(props.form.traceroot);

  return (
    <>
      <div className="inputs">
        <div className="entity-view">
          <label>Entity View</label>
          <input type="radio" name="view" value={ENTITY_VIEW} checked={view === ENTITY_VIEW} onChange={(e) => setView(e.target.value)} />
          <div>
            <label>entity:</label>
            <input
              type="text"
              id="entity"
              name="entity"
              value={entity}
              onChange={(e) => setEntity(e.target.value)}
              onFocus={() => setView(ENTITY_VIEW)}
            />
          </div>
          <div>
            <label>entityid:</label>
            <input
              type="text"
              id="entityid"
              name="entityid"
              value={entityid}
              onChange={(e) => setEntityid(e.target.value)}
              onFocus={() => setView(ENTITY_VIEW)}
            />
          </div>
          <div>
            <label>type:</label>
            <input type="text" id="type" name="type" value={type} onChange={(e) => setType(e.target.value)} onFocus={() => setView(ENTITY_VIEW)} />
          </div>
        </div>
        <div className="or">OR</div>
        <div className="traceroot-view">
          <label>Traceroot View</label>
          <input type="radio" name="view" value={TRACE_ROOT_VIEW} checked={view === TRACE_ROOT_VIEW} onChange={(e) => setView(e.target.value)} />
          <div>
            <label type="submit" value="Submit">
              traceroot:
            </label>
            <input
              type="text"
              id="traceroot"
              name="traceroot"
              value={traceroot}
              onChange={(e) => setTraceroot(e.target.value)}
              onFocus={() => setView(TRACE_ROOT_VIEW)}
            />
          </div>
        </div>
        {/* <div>
            <label>timerange:</label>
            <input type="text" id="timerange" name="timerange" />
          </div> */}
      </div>
      <button
        className="query-button"
        onClick={() =>
          props.submit({
            view,
            ...(view === ENTITY_VIEW && { entity, entityid, type }),
            ...(view === TRACE_ROOT_VIEW && { traceroot }),
          })
        }
      >
        Query
      </button>
    </>
  );
};

export default Inputs;
